:root {

	--nav_height: 3rem;
	@media screen and (min-width: 550px) {
		--nav_height: 5rem;
	}
	@media screen and (min-width: 730px) {
		--nav_height: 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		--nav_height: 3.125rem;
	}

	@media screen and (min-width: 1400px) {
		--nav_height: 3.75rem;
	}

	@media screen and (min-width: 1700px) {
		--nav_height: 5rem;
	}
}

.dark {

}

.light {

}

* {
	transition: all 200ms ease-in-out;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: transparent;
}

body {
	width: 100%;
	line-height: normal;
	overflow-x: hidden;
	scroll-behavior: smooth;
	max-width: 2500px;
}

a {
	text-decoration: none;
}
