.homeContainer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	height: 100%;
	background: #090a0a;

	@media screen and (min-width: 730px) {
		padding: 0 2.33rem ;
		
	}

	@media screen and (min-width: 1000px) {
		padding: 0 2.87rem ;
	
	}
	@media screen and (min-width: 1200px) {
		padding: 0 5.5rem ;
	
	}

	@media screen and (min-width: 1400px) {
		padding: 0 8.25rem ;
	
	}

	@media screen and (min-width: 1700px) {
		padding: 0 16.25rem ;

	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	margin-bottom: 6rem;
	gap: 0.5rem;
	padding: 0 0.63rem;
	margin-top: 3.07rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 4.07rem;
		gap: 1.42rem;
		padding: 0;
		margin-top: 3.31rem;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 5rem;
		gap: 1.75rem;
		margin-top: 3.81rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 6.3rem;
		gap: 2.2rem;
		margin-top: 4.75rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 7.5rem;
		gap: 2.62rem;
		margin-top: 8.12rem;
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;
	padding:0  1.24rem;

	@media screen and (min-width: 730px) {
		gap: 0.14rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.17rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.25rem;
	}
}
.heading {
	font-size: 2rem;
	color: #fff;
	font-weight: 700;

	span {
		background: linear-gradient(90deg, #4359f6 64.26%, #8458ff 80.21%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.subHeading {
	font-size: 1.09rem;
	color: #8f919e;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
		text-align: center;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.25rem;
	}
}
.headerImg {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		height: 100%;
		// aspect-ratio: 0.78;

		@media screen and (min-width: 730px) {
			aspect-ratio: 2.55;
		}
	}
}
.downloadContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	background-color: #4359f6;
	gap: 2.31rem;
	padding: 1.19rem 0.87rem 2.76rem;

	@media screen and (min-width: 730px) {
		padding: 0;
		flex-direction: row;
		gap: 0;
		padding: 4.81rem 0 1.89rem 3.37rem;
		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		padding: 5.87rem 0 3.39rem 4.08rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1.68rem;
		padding: 7.41rem 0 2.89rem 5.15rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 2rem;
		padding: 8.88rem 0 3.44rem 6.13rem;
	}
}
.downloadChildContainer {
	display: flex;
	flex-direction: column;
	// position: relative;
	gap: 2.31rem;

	@media screen and (min-width: 730px) {
		gap: 0;
		flex-direction: row;
	}
}
.downloadLeftContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	padding: 0 0.49rem 0 1.69rem;
	width: 100%;
	z-index: 0;

	@media screen and (min-width: 730px) {
		width: 50%;
		gap: 1.42rem;
		padding: 0;
		// padding: 1.46rem 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.7rem;
		// padding: 1.81rem 0;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
		// padding: 2.29rem 0;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
		// padding: 2.78rem 0;
	}
}
.downloadHeader {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}
.downloadImgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	img {
		width: 90%;
		aspect-ratio: 2.19 ;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 1.77;
			// height: 50%;
			// aspect-ratio: 1;
		}
	}
	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		align-items: flex-end;
		width: 50%;
		z-index: 1;
	}
}
.osLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1.1rem;
	// gap: 2.5rem;

	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.download_Os_container {
	display: flex;
	flex-direction: column;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.osLogos {
	height: 2rem;

	@media screen and (min-width: 730px) {
		height: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		height: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2rem;
	}
}
.downloadBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	@media screen and (min-width: 730px) {
		align-items: center;
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.downloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
	padding: 0.69rem 0.96rem;
	border-radius: 1.71rem;

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}

.mailContentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 6rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 5.76rem;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 7.07rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 8.93rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 10.62rem;
	}
}
.mailFeatures_Container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 2.03rem;
	padding: 0 1.5rem;
	text-align: center;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}
.mailFeatures_header {
	font-size: 1.875rem;
	font-weight: 700;
	text-align: center;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.mail_Features_content {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	padding-bottom: 1.08rem;

	@media screen and (min-width: 730px) {
		gap: 3.35rem;
		flex-direction: row;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.12rem;
		padding-bottom: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.2rem;
		padding-bottom: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.19rem;
		padding-bottom: 2rem;
	}
}
.mail_Features_content {
	display: flex;
	flex-direction: column;
	padding-bottom: 1.08rem;
	gap: 1rem;

	@media screen and (min-width: 730px) {
		gap: 3.35rem;
		flex-direction: row;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.12rem;
		padding-bottom: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.2rem;
		padding-bottom: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.19rem;
		padding-bottom: 2rem;
	}
}
.featureContainer {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@media screen and (min-width: 730px) {
		gap: 0.55rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
	}
}
.mail_feature {
	display: flex;
	flex-direction: row;
	// align-items: center;
	gap: 0.55rem;
	border-radius: 1rem;
	// background: rgba(67, 89, 246, 0.02);
	padding: 0.55rem;

	@media screen and (min-width: 730px) {
		gap: 0.55rem;
		padding: 0.55rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem;
		padding: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem;
		padding: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
		padding: 1rem;
	}
}
.featureIcons {
	height: 1.42rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
		height: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		height: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2.625rem;
	}
}
.featureDescription {
	font-size: 0.825rem;
	font-weight: 600;
	color: #ffffff;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 0.825rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.5rem;
	}
}
.featureDescriptionText {
	font-size: 0.625rem;
	font-weight: 400;
	color: #ffffff;
	text-align: left;

	@media screen and (min-width: 730px) {
		font-size: 0.625rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.94rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.125rem;
	}
}
.descriptionTextContainer {
	display: flex;
	flex-direction: column;
	gap: 0.55rem;

	@media screen and (min-width: 730px) {
		gap: 0.55rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
	}
}
.illustrationContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2.62rem;
	padding: 0 0.63rem;

	@media screen and (min-width: 730px) {
		flex-direction: column;
		gap: 3.76rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.62rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.83rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.94rem;
	}
}
.illustrationText {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 3.36rem 0 3.6rem;
}
.illustrationHeader {
	font-size: 2rem;
	font-weight: 700;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.illustrationSubHeader {
	font-size: 1.125rem;
	font-weight: 700;
	color: #ffffff;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 1.125rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
	span {
		color: #737784;
	}
}

.simplifiedPrivacyContainer {
	display: flex;
	width: 95%;
	// padding: 0 2.12rem;

	@media screen and (min-width: 730px) {
		padding: 0;
	}
}
.simplifiedPrivacyChild {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 3.25rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 4rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 5.04rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 6rem;
	}
}
.simplifiedHeader {
	display: flex;
	flex-direction: column;
}

.privacyContainers {
	display: flex;
	width: 100%;
	img {
		width: 100%;
		aspect-ratio: 1.49;
	}
}
.privacyContainer {
	display: flex;
	width: 100%;
	// display: grid;
	// width: 100%;
	// height: 100%;
	// grid-template-rows: repeat(3, 1fr);
	// grid-template-rows: auto;
	// gap: 1.2rem;

	@media screen and (min-width: 730px) {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: repeat(3, 1fr);
		align-content: flex-start;
		grid-template-rows: auto;
		gap: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1.26rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.5rem;
	}
}
.privacyContent {
	display: flex;
	flex-direction: column;
	// background-color: gray;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	border: 2px solid #2f3137;
	background: #000;
	padding: 1.62rem 2.2rem;
	justify-content: space-between;
	// padding: 3.3rem 3.88rem;
	gap: 1.77rem;
	// height: 10rem;

	img {
		width: 100%;
		aspect-ratio: .49;
	}

	@media screen and (min-width: 730px) {
		gap: 1.12rem;
		padding: 1.32425rem 2.57813rem 1.36rem 2.6125rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.35rem;
		padding: 1.60288rem 3.12056rem 1.62rem 3.16213rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.71rem;
		padding: 2.0225rem 3.9375rem 2.08rem 3.99rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.03rem;
		padding: 2.40775rem 4.6875rem 2.5rem 4.75rem;
	}
}
.privacyContent2 {
	display: flex;
	flex-direction: column;
	// background-color: gray;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	border: 2px solid #2f3137;
	background: #000;

	justify-content: space-between;
	padding: 1.8rem;
	gap: 1.77rem;
	// height: 10rem;

	img {
		width: 100%;
		border-radius: 2rem;
		aspect-ratio: .67;
	}

	@media screen and (min-width: 730px) {
		gap: 2.55rem;
		padding: 0 0 1.36rem 1.4rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 3.09rem;
		padding: 0 0 1.62rem 1.69rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.9rem;
		padding: 0 0 2.08rem 2.13rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4.64rem;
		padding: 0 0 2.5rem 2.5rem;
	}
}
.privacyContent3 {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 2rem;
	border: 2px solid #2f3137;
	background: #000;
	padding: 2.35rem 2.47rem;
	justify-content: space-between;
	// padding: 3.3rem 3.88rem;
	gap: 2.76rem;

	img {
		width: 100%;
		aspect-ratio: .59;
	}

	@media screen and (min-width: 730px) {
		gap: 2.61rem;
		padding: 0rem 1.51213rem 1.36rem 1.5125rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 3.16rem;
		padding: 0rem 1.83031rem 1.62rem 1.83069rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.99rem;
		padding: 0rem 2.30944rem 2.085rem 2.31rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4.75rem;
		padding: 0rem 2.74938rem 2.5rem 2.75rem;
	}
}
.illustration_Description {
	color: #d9dadf;
	font-size: 1.25rem;
	text-align: center;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 0.68rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.83rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.05rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.25rem;
	}
}
.illustration4img {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.05rem;
	img {
		width: 100%;
		aspect-ratio: 1.20;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.83;
		}
	}

	@media screen and (min-width: 730px) {
		padding: 1.375rem 4.74375rem 1.52669rem 4.70938rem;
		gap: 1.48294rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 2.1rem 7.245rem 2.33169rem 7.1925rem;
		gap: 2.34669rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 2.1rem 7.245rem 2.33169rem 7.1925rem;
		gap: 2.34669rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 2.5rem 8.625rem 2.77581rem 8.5625rem;
		gap: 2.77581rem;
	}
}
.mailPrivacyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1.19rem;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 2.03rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}
.mailContentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 6rem;

	@media screen and (min-width: 730px) {
		margin-bottom: 5.76rem;
	}

	@media screen and (min-width: 1000px) {
		margin-bottom: 7.07rem;
	}

	@media screen and (min-width: 1400px) {
		margin-bottom: 8.93rem;
	}

	@media screen and (min-width: 1700px) {
		margin-bottom: 10.62rem;
	}
}
.illustration4Text {
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.1rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2rem;
	}
}

.illustrationText {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 30%;
	}
}
.illustrationHeader {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.illustrationImage {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.3;
	}

	@media screen and (min-width: 730px) {
		width: 70%;
	}
}
.ilustrationImgContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.62rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 0.64rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 0.79rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.19rem;
	}
}
.illustrationTextImg {
	display: flex;
	flex-direction: column;
	gap: 2.42rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 1.2rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 1.46rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1.84rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 2.19rem;
	}
}
.setUsApartContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.44rem 1rem 0.22rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		padding: 0;
		gap: 2.37rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.91rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4.38rem;
	}
}
.setApartHeader {
	display: flex;
	flex-direction: column;
	padding: 0 1.69rem;
	gap: 0.4rem;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.49rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.61rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.73rem;
	}
}
.setApartImg {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		aspect-ratio: 0.57;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.58;
		}
	}
}
.mailFeatureSubHeader {
	font-size: 1.25rem;
	font-weight: 500;
	color: #8f919e;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
}
.footerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: #4359f6;
	padding: 1.1875rem 1.9875rem 5.05rem 1.9375rem;
	// padding: 7.7rem 0.56rem 7.7rem 1.13rem;
	gap: 2.56rem;

	@media screen and (min-width: 730px) {
		padding: 0.8725rem 0.48125rem 0.88238rem 5.5rem;
		gap: 10.38rem;
		// gap: 0;
		flex-direction: row-reverse;
		width: 100%;
		justify-content: space-between;
		border-radius: 1.08rem;
		margin-bottom: 2.66rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		padding: 1.05606rem 0.5825rem 1.06806rem 6.65713rem;
		gap: 12.57rem;
		margin-bottom: 4.01rem;
	}
	@media screen and (min-width: 1200px) {
		width: 85%;
	}
	@media screen and (min-width: 1400px) {
		padding: 1.33256rem 0.735rem 1.34763rem 8.4rem;
		border-radius: 1.68rem;
		gap: 15.85rem;
		margin-bottom: 6.04rem;
		width: 100%;
	}

	@media screen and (min-width: 1700px) {
		padding: 1.58638rem 0.875rem 1.60438rem 10rem;
		gap: 18.8rem;
		border-radius: 2rem;
		margin-bottom: 6.3rem;
	}
}
.footerLeftContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	// justify-content: center;
	gap: 1rem;
	width: 100%;
	z-index: 2;

	@media screen and (min-width: 730px) {
		align-items: flex-start;
		gap: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.7rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}
}
.footerText_header {
	font-size: 2rem;
	font-weight: 700;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}
.footerImgContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 2.56rem;

	.footerImg {
		display: flex;
		width: 50%;

		img {
			width: 100%;
			aspect-ratio: 0.47;
			z-index: 0;
	
		}
		@media screen and (min-width: 730px) {
			width: 100%;
		}
		@media screen and (min-width: 1000px) {
		}
		@media screen and (min-width: 1200px) {
			width: 100%;
		}
		@media screen and (min-width: 1400px) {
			width: 100%;
		}
	}
	
	@media screen and (min-width: 730px) {
		width: 50%;
		gap: 0;
	}

}
.footerLogoContainer {
	display: flex;
	flex-direction: row;
	// justify-content: space-between;
	align-items: center;
	gap: 1.1rem;
	// gap: 2.5rem;

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.download_Qr_scanner {
	display: flex;
	flex-direction: column;
	// gap:

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.footerDownload_container {
	display: flex;
	flex-direction: row;
	// gap:
	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.footerBtn_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.qrAndLogoContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 1.1rem;
}
